import urljoin from 'url-join';
import { defaultLocale } from './locales';

export function isStaticResourceLink(url: string): boolean {
  const lastPath = url.split('/').pop();
  const fileNameBits = lastPath && lastPath.split('.');
  if (fileNameBits && fileNameBits.length && fileNameBits.length > 1) {
    const resourceExt = fileNameBits.pop();
    return !!resourceExt;
  }
  return false;
}

export function isPhoneOrMailLink(path: string): boolean {
  return ['tel', 'mailto'].includes(path.split(':')[0]);
}

export function detectQueryString(url: string): boolean {
  // regex pattern for detecting querystring
  var pattern = new RegExp(/\?.+=.*/g);
  return pattern.test(url);
}

export function isIdLink(url: string) {
  return url[0] === '#';
}

export function isDirectLink(url: string | null) {
  if (url === null) return false;
  return isAbsoluteURL(url) || isIdLink(url) || isPhoneOrMailLink(url);
}

export function shouldAddTrailingSlash(url: string): boolean {
  return (
    url.slice(-1) !== '/' &&
    !(isStaticResourceLink(url) || detectQueryString(url) || isDirectLink(url))
  );
}

export function sanitizeUrl(url: string): string {
  return url.replace(/([^:])(\/{2,})/g, '$1/');
}

export function pathJoin(...args: string[]): string {
  return sanitizeUrl(urljoin(...args)).trim();
}

export function isAbsoluteURL(url: string) {
  return ['http://', 'https://'].some(proto => url.startsWith(proto));
}

export function joinPaths(baseURL: string, url: string) {
  if (isAbsoluteURL(url)) {
    return url;
  } else {
    return pathJoin(baseURL, url);
  }
}

export function resolveUrl(locale: string, path: string) {
  // In the case of an absolute URL, we don't want to mess with it. Just
  // Use what was supplied
  // Same with any links linking to an ID on the page

  if (!shouldAddTrailingSlash(path)) {
    return path;
  }
  return pathJoin(
    '/',
    locale === defaultLocale ? '' : locale.toLocaleLowerCase(),
    path,
    shouldAddTrailingSlash(path) ? '/' : ''
  );
}
